<template>
  <main>
    <h2>Identifiez-vous ou créez un compte</h2>
    <div v-if="stateLoading" class="spinner_container" >
      <Spinner></Spinner>
    </div>
    <div v-else class="card__wrapper">
      <article class="card login">
        <h3>J'ai déjà un compte</h3>
        <div class="error" id="error-login" style="display: none">
          <p>{{ error }}</p>
        </div>
        <form id="login-form" @submit.prevent="validateForm">

          <div class="form__row">
            <input
                class="input"
                type="email"
                name="login-email"
                v-model="loginForm.email"
                autocomplete="off"
                placeholder="E-mail"
                required
            />
          </div>
          <div class="form__row">
            <input
                class="input"
                :type="[showLogPassword ? 'text' : 'password']"
                name="login-password"
                v-model="loginForm.password"
                autocomplete="off"
                placeholder="Mot de passe"
                required
            />
            <button aria-label="show" type="button" class="button show-password" @click="pwdLogShow">
              <font-awesome-icon v-if="showLogPassword" icon="fa-solid fa-eye-slash"/>
              <font-awesome-icon v-else icon="fa-solid fa-eye"/>
            </button>
          </div>

          <div class="form__row">
            <router-link class="inactive-link" exact-active-class="active-link"
                         :to="{name: 'PasswordReset'}">Mot de passe oublié ?
            </router-link>
          </div>
          <!--					<div class="form__row custom-checkbox-wrapper">-->
          <!--						<input-->
          <!--							type="checkbox"-->
          <!--							name="remember-login"-->
          <!--							id="remember-login"-->
          <!--						/>-->
          <!--						<label for="remember-login">-->
          <!--							<span></span>-->
          <!--							Rester connecté-->
          <!--						</label>-->
          <!--					</div>-->
          <div class="form__row">
            <button aria-label="login" type="submit" class="button full width" @click="login">
              <span>Connexion</span>
            </button>
          </div>
        </form>
      </article>

      <article class="card signup">
        <div class="cover">
          <img v-lazy="IMG_8961" alt="oyster"/>
        </div>
        <h3>Je n'ai pas encore de compte</h3>
        <div class="error" id="error-registration" style="display: none">
          <p>Vous disposez déjà d'un compte avec cette adresse mail. <br>
            Veuillez vous authentifier ou cliquer sur mot de passe oublié </p>
        </div>

        <form id="registration-form" @submit.prevent="validateForm">
          <div class="form__row">
            <input
                class="input"
                type="text"
                id="first-name"
                name="first-name"
                v-model="registrationForm.first_name"
                placeholder="Prénom"
                autocomplete="off"
                required
            />
            <input
                class="input"
                type="text"
                id="last-name"
                name="last-name"
                v-model="registrationForm.last_name"
                placeholder="Nom"
                autocomplete="off"
                required
            />
          </div>
          <div class="form__row">
            <input
                class="input"
                type="email"
                name="signup-email"
                v-model="registrationForm.email"
                placeholder="E-mail"
                autocomplete="new-email"
                required
            />
          </div>
          <div class="form__row">
            <input
                class="input"
                :type="[showRegPassword ? 'text' : 'password']"
                name="signup-password"
                v-model="registrationForm.password"
                autocomplete="new-password"
                placeholder="Mot de passe"
                required
            />
            <button aria-label="show" type="button" class="button show-password white" @click="pwdRegShow">
              <font-awesome-icon v-if="showRegPassword" icon="fa-solid fa-eye-slash"/>
              <font-awesome-icon v-else icon="fa-solid fa-eye"/>
            </button>
          </div>
          <div id="zipElement" style="color: gray">
            <label style="color: whitesmoke">Où souhaitez-vous être livré ?</label>
            <AddressAutocomplete id="street"
                                 classname="input"
                                 :set-location="true"
                                 required
                                 v-on:placechanged="getAddressData"
            ></AddressAutocomplete>
          </div>

          <!--  TODO CAPCHA inside <div class="form__row"> -->

          <div class="form__row">
            <button id='createbtn'aria-label="registration" type="submit" class="button full width" @click="registration">
              <span>Créer un compte</span>
            </button>
          </div>
        </form>
      </article>
    </div>
  </main>
</template>

<script>

import {mapGetters} from "vuex";
import Spinner from "@/components/Spinner";
import AddressAutocomplete from "@/components/AddressAutocomplete";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import IMG_8961 from '@/assets-2/IMG_8961.webp'

library.add(faEye, faEyeSlash)

export default {
  name: "Registration",
  components: {'font-awesome-icon': FontAwesomeIcon, Spinner, AddressAutocomplete},
  computed: {
    ...mapGetters(['stateLoading']),
    ...mapGetters('auth', ['getLocation']),
    ...mapGetters('error', ['getErrorMessage']),
  },
  mounted() {
    // this.getStorageLocation();
  },
  data() {
    return {
      IMG_8961,
      showRegPassword: false,
      showLogPassword: false,
      error: null,
      registrationForm: {
        email: null,
        password: null,
        longitude: null,
        latitude: null,
        first_name: null,
        last_name: null,
        street: null,
        zip: null,
        country: null,
        city: null,
      },
      loginForm: {
        email: '',
        password: '',
      }
    }
  },
  methods: {
    getAddressData: function (addressData) {
      if (addressData != null| addressData != 'undefined'){
        if(addressData.longitude != null){this.registrationForm.longitude =addressData.longitude}
        if(addressData.latitude != null){this.registrationForm.latitude =addressData.latitude}
        if(addressData.city != null){this.registrationForm.city = addressData.city}
        if(addressData.country != null){this.registrationForm.country = addressData.country}
        if(addressData.zip != null){this.registrationForm.zip = addressData.zip}
        if(addressData.street != null){this.registrationForm.street = addressData.street}

      }
    },
    pwdLogShow() {
      this.showLogPassword = !this.showLogPassword;
    },
    pwdRegShow() {
      this.showRegPassword = !this.showRegPassword;
    },
    validateForm() {
    },
    registration: async function () {
      document.getElementById('error-registration').style.display = 'none';
      // await this.$store.dispatch('changeStateLoading', true)
      if(this.getLocation.longitude != null){this.registrationForm.longitude = this.getLocation.longitude}
      if(this.getLocation.latitude != null){this.registrationForm.latitude = this.getLocation.latitude}
      if(this.getLocation.city != null){this.registrationForm.city = this.getLocation.city}
      if(this.getLocation.country != null){this.registrationForm.country = this.getLocation.country}
      if(this.getLocation.zip != null){this.registrationForm.zip = this.getLocation.zip}
      if(this.getLocation.street != null){this.registrationForm.street = this.getLocation.street}

      if(this.registrationForm.longitude!=null){
        this.registrationForm.email = this.registrationForm.email.toLowerCase()
        this.registrationForm.last_name = this.registrationForm.last_name.toUpperCase()
        this.registrationForm.first_name = this.registrationForm.first_name[0].toUpperCase() + this.registrationForm.first_name.slice(1)
        const formData = this.registrationForm;
        formData['cache_key'] = localStorage.getItem("cache_key")
        await this.$store.dispatch('auth/registration', formData)
            .catch(err => {
              console.log('err', err)
              this.error = err;
              document.getElementById('error-registration').style.display = 'block';
            })
            .finally(() => {
              this.$store.dispatch('changeStateLoading', false)
              if (this.getErrorMessage === null) {
                this.$router.push({name: "EmailVerifySend", params: {'email': formData.email}})
              }
            })
      }else {
        this.$store.dispatch('error/setError', {'message': "Echec création compte", 'details': "votre compte n'a pas été créé, veuillez réessayer ou nous contacter"})
      }

    },
    login: async function () {
      document.getElementById('error-login').style.display = 'none';
      this.loginForm.email = this.loginForm.email.toLowerCase();
      const formData = this.loginForm;
      await this.$store.dispatch('auth/login', formData)
          .catch(err => {
            this.error = err;
            document.getElementById('error-login').style.display = 'block';
          })
          .finally(() => {
            if (this.getErrorMessage === null) {
              if (this.$route.params.fromPage === "cart") {
                this.$router.push({name: "Order"})
              }
              if (this.$route.params.fromPage === "gift") {
                this.$router.push({name: "Gift"})
              }
              if (!this.$route.params.fromPage) {
                this.$router.push({name: "Home"})
              }
            }
          })
    },
  },
}
</script>

<style lang="scss" type="text/scss" scoped>

main {
  @include flex(center, center, column, $gap: 20px);
  width: 85vw;
  margin: 50px auto;

  @media screen and (min-width: 768px) {
    width: 90vw
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
  }

  .spinner_container {
    @include flex($justify: center, $align: center, $direction: row, $wrap: nowrap, $gap: 0);
    position: relative;
    gap: 7.5%;
    width: 100%;
    margin-top: 50px;
  }
}

.card__wrapper {
  @include flex(center, center, column, $gap: 25px);
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }

  .card {
    @include flex(flex-start, center, column, $gap: 10px);
    width: 100%;
    max-width: 450px;
    box-shadow: 0 0.1875rem 1.5rem #00000033;
    border-radius: 10px;
    padding: 30px 5%;
    position: relative;
    overflow: hidden;

    &.login {
      h3 {
        color: $Brown;
        font-weight: 700;
      }
    }

    &.signup {
      background-size: cover;

      h3 {
        color: $White;
        font-weight: 700;
      }

      .cover {
        @include position(0, 0);
        width: 100%;
        height: 100%;
        z-index: -1;

        &::after {
          @include position(0, 0);
          @include color('background-color', $Blue, .8);
          z-index: 1;
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .form__row button {

        &:hover {
          border-color: $White;
          color: $White;
        }
      }
    }

    .error {
      // @include color('background-color', $Red, .3);
      background-color: rgb(238 206 206);
      border-radius: 5px;
      box-shadow: 0 2px 10px -2px #00000033;
      width: 100%;

      p, li {
        font-size: 14px;
        font-weight: 600;
        color: $Red;
        padding: 5px 10px;

      }
    }

    form {
      @include flex(flex-start, flex-start, column, $gap: 10px);
      width: 100%;

      .form__row {
        @include flex(center, center, $gap: 10px);
        width: 100%;
        text-align: center;

        a {
          color: $Blue;
          font-weight: 600;
          font-size: 14px;
          transition: .2s;

          &:hover, &:focus {
            opacity: .8;
          }
        }

        button {
          @media screen and (min-width: 576px) {
            padding: 10px 12%;
          }
          @media screen and (max-width: 575.99px) {
            width: 100%;
          }

          &.show-password {
            @include fixed-size(30px);
            border: none;
            padding: 5px;
            outline: none;

            &:not(.white) {
              background-color: $White;

              svg path {
                fill: $Brown;
              }
            }

            &.white {
              background-color: $Blue;

              svg path {
                fill: $White
              }
            }
          }
        }
      }
    }
  }

  .form__wrapper {
    @include flex(flex-start, flex-start, column, $gap: 20px);
    padding: 20px;

    form {
      @include flex(flex-start, flex-start, column, $gap: 5px);
      width: 100%;

      button {
        font-size: 16px;
        padding: 5px 10px;
        @media screen and (max-width: 575.99px) {
          width: 100%;
        }
      }

      input {
        appearance: none;
      }

      &__row {
        @include flex(flex-start, center, $gap: 5px);

      }

    }
  }
}
</style>